(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("node-fetch"));
	else if(typeof define === 'function' && define.amd)
		define("geoblaze", ["node-fetch"], factory);
	else if(typeof exports === 'object')
		exports["geoblaze"] = factory(require("node-fetch"));
	else
		root["geoblaze"] = factory(root["node-fetch"]);
})((typeof window !== 'undefined' ? window : typeof self !== 'undefined' ? self : typeof global !== 'undefined' ? global : typeof this !== 'undefined' ? this : undefined), function(__WEBPACK_EXTERNAL_MODULE__116__) {
return 